import React, { useEffect, useState } from 'react'
import css from './Content.module.css'
import { useCompaniesContentHistory } from '../../api/company'
import { Table, TableBody, TableRow, TableCell, TableHead, Pagination, Box, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material'
import { type CompanyContentHistory } from '../../types'
import { getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table'
import columns from './Columns'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'

const LIMIT = 10

interface TableProps {
  data: CompanyContentHistory[]
}
const TableContent: React.FC<TableProps> = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Table className={css.table}>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const Content: React.FC = (props) => {
  const [limit, setLimit] = useState<number>(LIMIT)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const { data: { data, total } = { data: [] as CompanyContentHistory[], total: 0 } } = useCompaniesContentHistory({
    limit,
    offset: (page - 1) * limit,
    search
  })

  return (
    <div className={css.container}>
      <PageHeaderText>Content History</PageHeaderText>
      <div className={css.search}>
        <div className={css.searchInput}>
          <TextField
            id="outlined-basic"
            label={'Name to search'}
            variant="outlined"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              setPage(1)
            }}
          />
          <Button
            className={css.searchButton}
            style={{ marginLeft: '15px' }}
            variant="outlined"
            onClick={() => {
              setSearch('')
              setPage(1)
            }}
          >
            Clear search
          </Button>
        </div>
        <div className={css.search_cpp}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Companies per page</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={limit}
              label="Companies per page"
              onChange={(e) => {
                setLimit(Number(e.target.value))
                setPage(1)
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <TableContent data={data} />
      <Box display="flex" marginTop={1} justifyContent="flex-end">
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          onChange={(e, v) => {
            setPage(v)
          }}
        />
      </Box>
    </div>
  )
}

export default Content
