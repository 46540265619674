import * as React from 'react'
import { Typography } from '@mui/material'
import css from './PageHeaderText.module.css'

type PageHeaderTextProps = {
  children: React.ReactNode
}

const PageHeaderText: React.FC<PageHeaderTextProps> = ({ children = '' }) => {
  return (
    <Typography variant="h4" className={css.text}>
      {children}
    </Typography>
  )
}

export default PageHeaderText
