import { type LookerStudioType, type Blog, type BlogStats, type QueryParams, type ResponseData } from '../types'
import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import instance from './client'

export const useBlogs = (params: QueryParams<Blog> & { name: string; url: string; plugin_version: string }): UseQueryResult<ResponseData<Blog>, AxiosError> => {
  return useQuery<unknown, AxiosError, ResponseData<Blog>>({
    queryKey: ['blogs', params.offset, params.search, params.limit, params.name, params.url, params.plugin_version],
    queryFn: async () => await instance.get('/blogs', { params }).then((data) => data.data)
  })
}

export const useBlog = (id?: number): UseQueryResult<Blog, AxiosError> => {
  return useQuery<unknown, AxiosError, Blog>({
    queryKey: ['blog', id],
    queryFn: async () => await instance.get('/blogs/' + id).then((data) => data.data),
    enabled: !!id
  })
}

export const useBlogStats = (id?: number): UseQueryResult<BlogStats, AxiosError> => {
  return useQuery<unknown, AxiosError, BlogStats>({
    queryKey: ['blog-stats', id],
    queryFn: async () => await instance.get(`/blogs/${id}/stats`).then((data) => data.data),
    enabled: !!id
  })
}

type UpdateBlogInput = Pick<Blog, 'url' | 'name'>
export const useUpdateBlog = (): UseMutationResult<Blog, AxiosError, { id: number; data: UpdateBlogInput }> => {
  const queryClient = useQueryClient()
  return useMutation<Blog, AxiosError, { id: number; data: UpdateBlogInput }>({
    mutationKey: ['update-blog'],
    mutationFn: async ({ id, data: input }) => await instance.patch(`/blogs/${id}`, input).then((data) => data.data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['blogs'])
      queryClient.setQueryData(['blog', data.id], data)
    }
  })
}

export const useUpdateLookerStudio = (): UseMutationResult<LookerStudioType, AxiosError, { blogId: number; iframe: string }> => {
  const queryClient = useQueryClient()
  return useMutation<LookerStudioType, AxiosError, { blogId: number; iframe: string }>({
    mutationKey: ['update-looker-studio'],
    mutationFn: async ({ blogId, iframe }) => await instance.post(`/blogs/${blogId}/looker-studio`, { iframe }).then((data) => data.data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['looker-studios'])
      queryClient.setQueryData(['looker-studio', data.id], data)
    }
  })
}

export const useLookerSudio = (blogId?: number): UseQueryResult<LookerStudioType, AxiosError> => {
  return useQuery<unknown, AxiosError, LookerStudioType>({
    queryKey: ['blog-looker-studio', blogId],
    queryFn: async () => await instance.get(`/blogs/${blogId}/looker-studio`).then((data) => data.data),
    enabled: !!blogId
  })
}
