import React from 'react'
import css from './CSDashboard.module.css'
import { useCSManagersStats } from '../../api/management'
import { Table, TableBody, TableRow, TableCell, TableHead, Box } from '@mui/material'
import { type CSManagerStat } from '../../types'
import { getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table'
import columns from './Columns'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import Loader from '../../components/loader/Loader'

interface TableProps {
  data: CSManagerStat[]
}
const TableContent: React.FC<TableProps> = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Table className={css.table}>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell, cellIndex) => (
              <TableCell key={cell.id} width={cellIndex > 1 ? '14%' : undefined}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const CSDashboard: React.FC = () => {
  const { data, isLoading } = useCSManagersStats()

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={css.container}>
      <PageHeaderText>CS Dashboard</PageHeaderText>
      {data?.length ? (
        <Box marginTop={1}>
          <TableContent data={data} />
        </Box>
      ) : (
        <Box marginTop={2} textAlign="center">
          No CS managers found
        </Box>
      )}
    </div>
  )
}

export default CSDashboard
