import React from 'react'
import TextField from '@mui/material/TextField'
import { type FormikProps } from 'formik'
import { type Company as CompanyType, RenewableCreditType } from '../../types'
import { Box, Grid, Typography } from '@mui/material'
import get from 'lodash/get'
import DatePicker from '../datePicker/DatePicker'
import moment from 'moment'

const getFieldPath = (index: number, key: string): string => `credit_renewals[${index}].${key}`

const getError = (index: number, key: string, formik: CreditRenewalsFormProps['formik']): string | undefined => {
  const fullKey = `[${index}].${key}`
  return get(formik.touched.credit_renewals, fullKey, '') && get(formik.errors.credit_renewals, fullKey, '')
}

interface CreditRenewalsFormProps {
  formik: FormikProps<CompanyType & { reset: boolean }>
}

const fieldsMap: Record<RenewableCreditType, Record<string, any>> = {
  [RenewableCreditType.KEYWORDS_TRACKED]: {
    label: 'Keywords Tracked'
  },
  [RenewableCreditType.PAGES_ANALYZED]: {
    label: 'Pages Analyzed'
  }
}

const CreditRenewalsForm: React.FC<CreditRenewalsFormProps> = ({ formik }) => {
  return (
    <>
      <Box>
        {(formik.values.credit_renewals ?? []).map((credit, index) => (
          <Grid container spacing={2} key={index} alignItems="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={12} sm={3}>
              <Typography>{fieldsMap[credit.credit_type as RenewableCreditType]?.label || ''}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                error={!!getError(index, 'value', formik)}
                helperText={getError(index, 'value', formik)}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                name={getFieldPath(index, 'value')}
                label="Value"
                type="number"
                value={formik.values.credit_renewals?.[index]?.value}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DatePicker
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                error={!!getError(index, 'next_renewal_at', formik)}
                helperText={getError(index, 'next_renewal_at', formik)}
                name={getFieldPath(index, 'next_renewal_at')}
                label="Next Renewal On"
                value={credit.next_renewal_at ? new Date(credit.next_renewal_at) : null}
                onChange={(v) => formik.setFieldValue(getFieldPath(index, 'next_renewal_at'), moment(v).startOf('day').add(12, 'hours').toISOString()) as any}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                error={!!getError(index, 'delay', formik)}
                helperText={getError(index, 'delay', formik)}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                name={getFieldPath(index, 'delay')}
                label="Delay (days)"
                type="number"
                value={credit.delay}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  )
}

export default CreditRenewalsForm
