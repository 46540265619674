import React, { useMemo } from 'react'
import Empty from '../Empty/Empty'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { useBlogStats } from '../../api/blog'
import Spinner from '../../components/spinner/Spinner'
import { type BlogStats as BlogStatsType } from '../../types'

interface BlogStatsProps {
  stats: BlogStatsType
}

const BlogStats: React.FC<BlogStatsProps> = ({ stats }) => {
  const data = useMemo(
    () => ({
      posts: [
        { title: 'Generated', subheader: 'Posts', number: stats.post_generated },
        { title: 'Updated', subheader: 'Posts', number: stats.post_updated },
        { title: 'Reviewed', subheader: 'Posts', number: stats.post_reviewed }
      ],
      other: [{ title: 'Keywords', subheader: 'Rank Tracker', number: stats.keywords }]
    }),
    [stats]
  )

  return (
    <div>
      <Grid container spacing={2}>
        {data.posts.map((item, index) => (
          <Grid item xs={4} key={index}>
            <Card>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {item.subheader}
                </Typography>
                <Typography variant="h6" component="div">
                  {item.title}
                </Typography>
                <Typography variant="h4" color="primary">
                  {item.number}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={3} />
        {data.other.map((item, index) => (
          <Grid item xs={6} key={index}>
            <Card>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {item.subheader}
                </Typography>
                <Typography variant="h6" component="div">
                  {item.title}
                </Typography>
                <Typography variant="h4" color="primary">
                  {item.number}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const Container: React.FC<{ blogId: number }> = ({ blogId }) => {
  const { data, isLoading, error } = useBlogStats(blogId)
  const message = (error?.response?.data ?? error?.message ?? 'Wrong Blog Id') as string
  if (isLoading) return <Spinner marginY={8} />
  if (data) return <BlogStats stats={data} />
  return <Empty message={message} />
}

export default Container
