import React, { forwardRef, type FC } from 'react'
import ReactDatePicker from 'react-datepicker'
import TextField, { type TextFieldProps } from '@mui/material/TextField'
import 'react-datepicker/dist/react-datepicker.css'

interface DatePickerProps {
  label?: string
  name?: string
  disabled?: boolean
  onBlur?: (e: any) => void
  error?: boolean
  helperText?: string
  onChange: (date: Date | null) => void
  value: Date | null
}

const CustomDPInput = forwardRef<HTMLInputElement, TextFieldProps>(({ name, label, value, onClick, error, disabled }, ref) => (
  <TextField
    ref={ref}
    error={error}
    name={name}
    value={value}
    onClick={onClick}
    label={label}
    disabled={disabled}
    variant="outlined"
    fullWidth
    InputProps={{
      readOnly: true
    }}
  />
))
CustomDPInput.displayName = 'CustomDPInput'

const DatePicker: FC<DatePickerProps> = ({ label, value, onChange, onBlur, name, disabled, error, helperText }) => {
  return <ReactDatePicker dateFormat="dd/MM/yyyy" onBlur={onBlur} name={name} disabled={disabled} selected={value} onChange={onChange} customInput={<CustomDPInput label={label} error={error} helperText={helperText} />} />
}

export default DatePicker
