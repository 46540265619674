import { ROUTES } from './types'

export const locale = {
  response: {
    success: 'success'
  },
  permissions: {
    e401: 'You should be authorized',
    e403: "You don't have enough permissions to view requested resource"
  }
}

export const ROUTES_LABELS: { [key in ROUTES]: string } = {
  [ROUTES.USERS]: 'Users',
  [ROUTES.REQUESTS]: 'Requests',
  [ROUTES.CONFIG]: 'Config',
  [ROUTES.COMPANIES]: 'Companies',
  [ROUTES.KEYWORDS]: '',
  [ROUTES.ALL]: '',
  [ROUTES.INDEX]: '',
  [ROUTES.LOGIN]: '',
  [ROUTES.PARAMS]: '',
  [ROUTES.NEW]: '',
  [ROUTES.BLOGS]: 'Blogs',
  [ROUTES.TEMPLATES]: 'Templates',
  [ROUTES.CONTENT]: 'Content',
  [ROUTES.CS_DASHBOARD]: 'CS Dashboard'
}
