import { useContext, useMemo, useState } from 'react'
import { type FormikHelpers } from 'formik'

import { type Template } from '../types'
import { useUpdateTemplate } from '../api/template'
import MessageContext from '../contexts/MessageContext'

type UseTemplate = {
  data?: Template
  fields: string[]
  selectedTab: number
  tabNames: string[]
  onSubmit: (values: Template, helpers: FormikHelpers<Template>) => void
  switchTab: (newTabIdx: number) => void
}

type UseTemplateProps = {
  data: Template
  filter?: string
}

const generalTabPrefixes = ['company', 'existed', 'image', 'knowledge', 'rank']

const useTemplate = ({ data, filter }: UseTemplateProps): UseTemplate => {
  const addMessage = useContext(MessageContext)
  const updateTemplate = useUpdateTemplate()
  const [selectedTab, setSelectedTab] = useState(0)

  const fields = useMemo<string[]>(() => {
    return data?.params.common ? Object.keys(data.params.common).sort() : []
  }, [data])

  // map each field to own tab
  const tabNamesMap = useMemo<Record<string, string>>(() => {
    const tabMap: Record<string, string> = {}

    fields.forEach((key) => {
      const tabName = key.split('_')[0]

      const shouldBeInGeneralTab = generalTabPrefixes.includes(tabName)

      shouldBeInGeneralTab ? (tabMap[tabName] = 'general') : (tabMap[tabName] = tabName)
    })

    return tabMap
  }, [fields])

  // array of unique tab names
  const tabNames = useMemo<string[]>(() => {
    return Array.from(new Set(fields.map((key) => tabNamesMap[key.split('_')[0]])))
  }, [fields, tabNamesMap])

  // filter fields to display based on active tab & search term
  const filteredFields = useMemo<string[]>(() => {
    const selectedTabName = tabNames[selectedTab]
    const fieldsForTab = fields.filter((key) => tabNamesMap[key.split('_')[0]] === selectedTabName)

    if (filter) {
      return fieldsForTab.filter((key) => key.toLowerCase().includes(filter.toLowerCase() || ''))
    }

    return fieldsForTab
  }, [fields, selectedTab, tabNames, tabNamesMap, filter])

  const onSubmit = (values: Template, helpers: FormikHelpers<Template>): void => {
    helpers.setSubmitting(true)
    updateTemplate
      .mutateAsync(values)
      .catch((err) => {
        addMessage({
          id: Date.now(),
          message: err.response?.data || err.message
        })
      })
      .finally(() => {
        helpers.setSubmitting(false)
      })
  }

  return {
    data,
    fields: filteredFields,
    selectedTab,
    tabNames,
    onSubmit,
    switchTab: setSelectedTab
  }
}

export default useTemplate
