import React, { useContext, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Dashboard from './pages/Dashboard/Dashboard'
import reportWebVitals from './reportWebVitals'
import AuthContext, { AuthContextProvider } from './contexts/AuthContext'
import MessageContext, { CREDIT_WARNING_CONTEXT, MessageContextProvider } from './contexts/MessageContext'
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { PERMISSION, ROUTES } from './types'
import Login from './pages/Login/Login'
import Empty from './pages/Empty/Empty'
import RouteProtection from './components/RouteProtection'
import Config from './pages/Config/Config'
import Companies from './pages/Companies/Companies'
import Company from './pages/Company/Company'
import Users from './pages/Users/Users'
import Requests from './pages/Requests/Requests'
import Keywords from './pages/Keywords/Keywords'
import NewUser from './pages/NewUser/NewUser'
import Header from './components/header/Header'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Template from './pages/Template/Template'
import './global.d'
import { useCompaniesExpired } from './api/company'
import User from './pages/User/User'
import Blog from './pages/Blog/Blog'
import Content from './pages/Content/Content'
import CSDashboard from './pages/CSDashboard/CSDashboard'
import Blogs from './pages/Blogs/Blogs'
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const Main: React.FC = () => {
  const { data } = useCompaniesExpired()
  const addMessage = useContext(MessageContext)
  const navigate = useNavigate()
  useEffect(() => {
    if (data?.length) {
      data.forEach((c) => {
        addMessage({
          id: `notify_company_expired_${c.id}`, // any prefix
          message: `You have ${c.days} days to update credits for ${c.name} company`,
          severity: 'warning',
          onClick: () => {
            navigate(ROUTES.COMPANIES + '/' + c.id)
          },
          context: CREDIT_WARNING_CONTEXT
        })
      })
    }
  }, [data])

  return (
    <div>
      <Header />
      <Outlet />
    </div>
  )
}

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <MessageContextProvider>
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>
            <Route
              path={ROUTES.INDEX}
              element={
                <RouteProtection>
                  <Main />
                </RouteProtection>
              }
            >
              <Route
                index
                element={
                  <RouteProtection>
                    <Dashboard />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.CONFIG}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN]}>
                    <Config />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.USERS}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN]}>
                    <Users />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.USERS + ROUTES.PARAMS}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN]}>
                    <User />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.USERS + ROUTES.NEW}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN]}>
                    <NewUser />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.COMPANIES}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN, PERMISSION.MEMBER]}>
                    <Companies />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.COMPANIES + ROUTES.PARAMS}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN, PERMISSION.MEMBER]}>
                    <Company />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.COMPANIES + ROUTES.PARAMS + ROUTES.KEYWORDS}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN]}>
                    <Keywords />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.BLOGS}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN]}>
                    <Blogs />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.BLOGS + ROUTES.PARAMS}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN, PERMISSION.MEMBER]}>
                    <Blog />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.REQUESTS}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN, PERMISSION.MEMBER]}>
                    <Requests />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.TEMPLATES}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN, PERMISSION.MEMBER]}>
                    <Template />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.CONTENT}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN]}>
                    <Content />
                  </RouteProtection>
                }
              />
              <Route
                path={ROUTES.CS_DASHBOARD}
                element={
                  <RouteProtection permissions={[PERMISSION.ADMIN]}>
                    <CSDashboard />
                  </RouteProtection>
                }
              />
            </Route>
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.ALL} element={<Empty />} />
          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </MessageContextProvider>
  </QueryClientProvider>
  // </React.StrictMode>
)

reportWebVitals()
