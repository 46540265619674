import instance from './client'
import { type QueryParams, type Request, type ResponseData } from '../types'
import { type AxiosError } from 'axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const useRequests = (params: QueryParams<Request<any>> & { company_name?: string; blog_id?: number }): UseQueryResult<ResponseData<Request<any>>, AxiosError> => {
  console.log({ params })
  return useQuery<unknown, AxiosError, ResponseData<Request<any>> & { company_name?: string; blog_id?: number }>({
    queryKey: ['requests', params.limit, params.offset, params?.company_id, params?.type, params?.request_id, params?.company_name, params?.blog_id],
    queryFn: async () => await instance.get('/requests', { params }).then((data) => data.data)
  })
}
